



















import STextarea from '@/common/components/STextarea.vue';
import DynamicQuestionMixin from '@/tasks/mixins/dynamic-question';
import AiLoadingOverlay from '@/tasks/components/AiLoadingOverlay.vue';

export default {
  name: 'UOttawaPostLab5Discussion',
  components: {AiLoadingOverlay, STextarea},
  mixins: [DynamicQuestionMixin()],
  props: {
    isSubmitting: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      inputs: {
        part1: null,
        part2: null,
        part3: null,
        part4: null,
        part5: null,
        part6: null,
        part7: null,
        part8: null,
        part9: null,
        part10: null,
      },
      questions: [
        {
          text: '1) Did you achieve your objective?',
          inputName: 'part1',
        },
        {
          text: '2) What design flaws that affected your results? How did you counter these flaws? Could you compensate for them? If not, how did you factor the flaws into your results?',
          inputName: 'part2',
        },
        {
          text: '3) Is this a feasible method for the project? Would a different method have a better effect?',
          inputName: 'part3',
        },
        {
          text: '4) In this experiment, you chose either volume or pressure to measure the initial rate. Do you think choosing the other option would have been better? If so, why, and if not, why not?',
          inputName: 'part4',
        },
        {
          text: '5) What were some of the safety/health/cost considerations you took into account during this experiment?',
          inputName: 'part5',
        },
        {
          text: '6) Is there a better way to do the experiment?',
          inputName: 'part6',
        },
        {
          text: '7) Is the partial order you calculated reasonable?',
          inputName: 'part7',
        },
        {
          text: '8) Are the activation energies for catalase and KI reasonable? Explain what it means if they are different or similar?',
          inputName: 'part8',
        },
        {
          text: '9) Which is the more effective catalyst? Why?',
          inputName: 'part9',
        },
        {
          text: '10) Any further discussion points can be written below',
          inputName: 'part10',
        },
      ],
    };
  },
};
